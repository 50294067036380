import moment from 'moment';
import numeral from 'numeral';
import humanizeDuration from 'humanize-duration';
import 'moment/locale/ar' // without this line it didn't work
import debug from './DebugLog';
import i18next from 'i18next';

moment.locale('ar');

var symbolMap = {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '0': '0'
}, numberMap = {
    '١': '1',
    '٢': '2',
    '٣': '3',
    '٤': '4',
    '٥': '5',
    '٦': '6',
    '٧': '7',
    '٨': '8',
    '٩': '9',
    '٠': '0'
}
let momentArLocale = {
    weekdays: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
    weekdaysShort: 'الأحد_الإثنين_الثلاثاء_الأربعاء_الخميس_الجمعة_السبت'.split('_'),
    preparse: function (string) {
        return string.replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
            return numberMap[match];
        }).replace(/،/g, ',');
    },
    postformat: function (string) {
        return string.replace(/\d/g, function (match) {
            return numberMap[match] || match;
        }).replace(/,/g, '،');
    },
    meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
            return ' صباحا ';
        } else {
            return ' مساء ';
        }
    },
    relativeTime: {
        s: "ثانية",
        ss: function (number, withoutSuffix, key, isFuture) {
            let literal = "ثانية";

            if (number === 2) {
                literal = "ثانيتين";
            }

            if (number > 2 && number < 11) {
                literal = `${number} ثوان`;
            }

            if (number > 11) {
                literal = `${number} ثانية`;
            }

            return literal;
        },
        m: "دقيقة",
        mm: function (number, withoutSuffix, key, isFuture) {
            let literal = "دقيقة";

            if (number === 2) {
                literal = "دقيقتين";
            }

            if (number > 2 && number < 11) {
                literal = `${number} دقائق`;
            }

            if (number > 11) {
                literal = `${number} دقيقة`;
            }

            return literal;
        },
        h: "ساعة",
        hh: function (number, withoutSuffix, key, isFuture) {
            let literal = "ساعة";

            if (number === 2) {
                literal = "ساعتين";
            }

            if (number > 2 && number < 11) {
                literal = `${number} ساعات`;
            }

            if (number > 11) {
                literal = `${number} ساعة`;
            }
            return literal;
        },
        d: "يوم",
        dd: function (number, withoutSuffix, key, isFuture) {
            let literal = "يوم";

            if (number === 2) {
                literal = "يومين";
            }

            if (number > 2 && number < 11) {
                literal = `${number}  ايام`;
            }

            if (number > 11) {
                literal = `${number} يوماً`;
            }

            return literal;
        },
        M: "شهر",
        MM: function (number, withoutSuffix, key, isFuture) {
            let literal = "شهر";

            if (number === 2) {
                literal = "شهرين";
            }

            if (number > 2 && number < 11) {
                literal = `${number}  شهور`;
            }

            if (number > 11) {
                literal = `${number} شهراً`;
            }

            return literal;
        },
        y: "سنة",
        yy: function (number, withoutSuffix, key, isFuture) {
            let literal = "سنة";

            if (number === 2) {
                literal = "سنتين";
            }

            if (number > 2 && number < 11) {
                literal = `${number}  سنين`;
            }

            if (number > 11) {
                literal = `${number} سنة`;
            }

            return literal;
        }
    }
}

moment.locale('ar', {
    weekdaysShort: momentArLocale.weekdaysShort,
    postformat: momentArLocale.postformat
});


/**
 * Return the Full Date Formated 
 * @example الأربعاء 12 سبتمبر
 * @param {date} trip_date 
 * @param {string} lang 
 * @author Ojja
 */
export function FullDateNoYear(trip_date, lang = 'ar') {
    // set the Locare to Arabic 
    // moment.locale(lang, momentArLocale);
    // chec if momment object 
    if (moment.isMoment(trip_date)) {
        let new_date = trip_date.locale(lang)//, momentArLocale);
        new_date = new_date.format("dddd D MMMM")

        if (lang === 'ar') {
            new_date = new_date.replace(/[١٢٣٤٥٦٧٨٩٠]/g, (match) => numberMap[match]);
        }

        return new_date;
    }
    // if not return date 
    let parsed_time = moment.unix(trip_date).format("ddd D MMMM")

    return parsed_time
}


/**
 * Return the Full Date Formated 
 * @example الأربعاء 12 سبتمبر  2018
 * @param {date} trip_date 
 * @param {string} lang 
 * @author Ezzat
 */
export function FullDate(trip_date, lang = 'ar') {
    // set the Locare to Arabic 
    // moment.locale(lang, momentArLocale);
    // chec if momment object 
    if (moment.isMoment(trip_date)) {
        let new_date = trip_date.locale(lang)//, momentArLocale);
        return new_date.format("ddd D MMMM  YYYY")
    }
    // if not return date 
    let parsed_time = moment.unix(trip_date).format("ddd D MMMM  YYYY")

    return parsed_time
}

/**
 * Return the Full Date Formated  FROM Trips APIs
 * @example الأربعاء 12 سبتمبر
 * @param {date} trip_date 
 * @param {string} lang 
 * @author Ojja
 */
export function tripFullDateNoYear(trip_date, lang = 'ar') {
    // set the Locare to Arabic 
    // moment.locale(lang, momentArLocale);
    // chec if momment object 
    if (moment.isMoment(trip_date)) {
        let new_date = trip_date.locale(lang)//, momentArLocale);
        return new_date.format("ddd D MMMM")
    }
    // if not return date 
    let parsed_time = moment(trip_date).locale(lang).format("ddd D MMMM")
    return parsed_time
}


/**
 * Return the Full Date Digit Formated  
 * @example 20/09/2018
 * @param {date} trip_date 
 * @author Ezzat
 */
export function fullDateDigits(trip_date) {
    // set the Locare English Arabic 
    moment.locale('en');
    // chec if momment object 
    if (moment.isMoment(trip_date)) {
        let new_date = trip_date.locale('en');
        return new_date.format("DD/MM/YYYY").toString()
    }
    // if not return 5ara 
    let parsed_time = moment(trip_date).format("DD/MM/YYYY")
    return parsed_time.toString()
}


/**
 * Return the  Day Numbered
 * @example الأثنين 15
 * @param {date} trip_date 
 * @author Ezzat
 */
export function dayNamedAndNumbered(trip_date, lang = 'ar') {
    // set the Locare to Arabic 
    // moment.locale(lang, momentArLocale);
    // chec if momment object 
    if (moment.isMoment(trip_date)) {
        let new_date = trip_date.locale(lang)//, momentArLocale);
        return new_date.format(lang === 'ar' ? "dddd D" : "ddd D")
    }
    // if not return date 
    let parsed_time = moment.unix(trip_date).format("ddd D")

    return parsed_time
}



/**
 * Return Formatted Trip Price
 * @param {*} price 
 * @author Ezzat 
 */
export function tripPrice(price) {
    return numeral(price).format('0.00');
}

/**
 * Return the Trip Time for both departure and arrival
 * @example 10:40
 * @param {string} time 
 * @param {string} duration in minutes 
 * @author Ezzat
 */
export function tripTime(time, duration = null, format = 'hh:mm') {
    let parsed_time
    if (duration) {
        parsed_time = moment(time).locale('en').add(duration, 'minutes').format(format)
    } else {
        parsed_time = moment(time).locale('en').format(format)
    }
    return parsed_time
}

/**
 * Return the Trip Day Period
 * @example صباحا 
 * @param {string} time 
 * @param {string} duration in minutes 
 * @author Ezzat
 */
export function tripTimeSegment(time, duration = null, lang = 'ar') {
    let parsed_time
    if (duration) {
        parsed_time = moment(time).locale('en').add(duration, 'minutes').format("A")
    } else {
        parsed_time = moment(time).locale('en').format("A")
    }
    if (parsed_time === 'AM') {
        if (lang === 'ar') {
            parsed_time = 'صباحاً';
        }
    } else if (parsed_time === 'PM') {
        if (lang === 'ar') {
            parsed_time = 'مساءاً'
        }
    }

    return parsed_time;
}

/**
 * Convert integer time segment to String Time segment
 * @author Ezzat
 */
export function tripTimeSegmentName(time_segment) {
    const lang = i18next.language;
    let ts = [];
    if (lang === 'ar') {
        ts = ['صباحا', 'مساءاً'];
    } else {
        ts = ['AM', 'PM']
    }
    return ts[time_segment];
}

export function nativeDateToTimestamp(e, constraints, callback, onError) {
    let selectedDate = e.target.value;

    if (selectedDate.length === 0) {
        selectedDate = constraints.min;
    }

    if (moment(selectedDate).isBefore(moment(constraints.min))) {
        onError('below_min')
        return;
    }

    if (moment(selectedDate).isAfter(moment(constraints.max))) {
        onError('above_max')
        return;
    }

    callback(new Date(selectedDate).getTime(), { disabled: false })
}


/********************************* OLD NOT USED PROBABLY************************* */

/**
 * Convert 23:00:00 or 28:31:00 into normal 12 Hour format
 * @author Ezzat
 */
export function returnParsedTime(time) {
    if (moment.isMoment(time)) {
        return time.format("hh:mm ")
    }
    // if not return date 
    let parsed_time = moment(time * 1000).format("hh:mm ") // 4     
    return parsed_time
}



// Get the Position of the nth repated term
function getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
}

export function getTimeDiff(start, end) {
    const startTime = moment(start).utc();
    const endTime = moment(end).utc();
    return endTime.diff(startTime);
}

export function getTimeDuration(targetDateStr, fromDateStr = moment().toISOString()) {
    const deadline = moment(targetDateStr).utc();
    const fromDate = moment(fromDateStr).utc();
    return moment.duration(deadline.diff(fromDate));
}

export function tripDuration(seconds) {
    // return  numeral(seconds).format('00:00:00').slice(0,  -3);   //slicing is to remove ":00"  to remove seconds
    return humanizeDuration(seconds * 1000, {
        language: 'ar',
        delimiter: ' و '
    })  // '3 segundos'

}
export function secondsToMinutes(seconds) {
    return numeral(seconds).format('00:00:00');
}

export function amOrPm(time_segment) {
    if (time_segment === 1 || time_segment === 2) {
        return 'AM'
    }
    return 'PM'
}

